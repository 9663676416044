<template>
	<div class="user-manage">
		<jw-table ref="table" v-bind="table" @search="handlerSearch" />
	</div>
</template>
<script lang="jsx">
import Server from './apis'
export default {
  data () {
    return {
      table: {
        data: [],
        page: { total: 0 },
        cols: [
          {
            label: '头像',
            fuc: (row, h) => {
              return row.avatar ? <img class='user-manage-ava' src={row.avatar} width='40px' /> : '-'
            }
          },
          {
            label: '用户名',
            prop: 'nickname'
          },
          {
            label: 'unionid',
            prop: 'unionid'
          },
          {
            label: '注册时间',
            prop: 'gmtCreate',
            type: 'time',
            options: 'yyyy-mm-dd'
          }
        ]
      }
    }
  },
  mounted () {
    this.$refs.table.search()
  },
  methods: {
    handlerSearch ({ page, size }) {
      Server.getUserList({ pageIndex: page, pageSize: size }).then(res => {
        console.log(res)
        this.table.data = res.data.users
        this.table.page = res.data.page
      })
    }
  }
}
</script>
<style lang="less" scoped>
.user-manage {
  padding: 20px;
  /deep/ .user-manage-ava {
    border-radius: 100%;
  }
}
</style>
