import Api from '@/apis/index'

class Server extends Api {
  /**
   * 获取用户列表
  */
  getUserList (params) {
    return this.$http.get(`${Api.path.apiJob}/partner/${this.userInfo.partnerId}/users`, { params })
  }
}

export default new Server()
